<!-- 添加编辑计价弹窗 -->
<template>
  <el-dialog
      width="60%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'编辑计价':'添加计价'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px">
      <el-row :gutter="18">
        <!--<el-col :span="6">-->
        <!--  <el-form-item label="公式名称:" prop="name">-->
        <!--    <el-input-->
        <!--        clearable-->
        <!--        :maxlength="20"-->
        <!--        v-model="form.name"-->
        <!--        placeholder="请输入公式名称"/>-->
        <!--  </el-form-item>-->
        <!--</el-col>-->
        <!--<el-col :span="6">-->
        <!--  <el-form-item label="优先级:" prop="priority">-->
        <!--    <el-select-->
        <!--        clearable-->
        <!--        v-model="form.priority"-->
        <!--        placeholder="请选择"-->
        <!--        class="ele-fluid">-->
        <!--      <el-option-->
        <!--          v-for="(item) in dict.type.valuation_priority"-->
        <!--          :label="item.label"-->
        <!--          :value="parseInt(item.value)"-->
        <!--      />-->
        <!--    </el-select>-->
        <!--  </el-form-item>-->
        <!--</el-col>-->
        <!--<el-col :span="6">-->
        <!--  <el-form-item label="计价类型:" prop="taximeter_type">-->
        <!--    <el-select-->
        <!--        clearable-->
        <!--        v-model="form.taximeter_type"-->
        <!--        placeholder="请选择"-->
        <!--        class="ele-fluid">-->
        <!--      <el-option-->
        <!--          v-for="(item) in dict.type.valuation_type"-->
        <!--          :label="item.label"-->
        <!--          :value="parseInt(item.value)"-->
        <!--      />-->
        <!--    </el-select>-->
        <!--  </el-form-item>-->
        <!--</el-col>-->
        <el-col :span="12">
          <el-form-item label="救援类型:" prop="">
            <el-radio v-model="radio" label="1">事故救援</el-radio>
            <el-radio v-model="radio" label="3">高速救援</el-radio>
            <el-radio v-model="radio" label="2">非事故救援</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="启用:" prop="">
            <img v-if="form.status == 0" style="width: 76px;height: 34px; cursor: pointer;" src="@/assets/images/home/yiqiyong.png" alt="" @click="changeStatus()">
            <img v-else style="width: 76px;height: 34px; cursor: pointer;" src="@/assets/images/home/weiqiyong.png" alt="" @click="offState()">
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 主体内容 -->
      <el-row :gutter="15">
        <!--左-->
        <el-col :span="18">
          <div class="pricingRules">
            <!-- 拖车 -->
            <div class="pricing" v-for="(item,index) in dataList" :key="index">
              <div class="pricingRules_t_bg">
                <el-checkbox v-model="item.switch"></el-checkbox>
                <span style="vertical-align: middle; margin-left: 10px;">{{item.title}}</span>
                <el-radio-group v-model="item.billing_model" style="margin-left: 10px;" v-if="item.switch == true">
                  <el-radio label="mileage">里程计费</el-radio>
                  <el-radio label="fixed_price">一口价</el-radio>
                </el-radio-group>
              </div>

              <div v-if="item.indexes == 200000000001 || item.indexes == 200000000011 || item.indexes == 200000000012">
                <div v-if="item.switch == true">
                  <div v-for="(it,idx) in item.data.mileage" :key="idx" v-if="item.billing_model == 'mileage'">
                    <div>
                      <!--去程-->
                      <div class="trip">
                        <span>{{it.title}}</span>
                        <!--<el-checkbox v-model="it.status" @change="dianji">{{it.title}}</el-checkbox>-->
                      </div>
                      <!--背程-->
                      <el-row>
                        <el-col :span="12" v-for="(shuju,xiabiao) in it.data" :key="xiabiao">
                          <!--v-if="it.status == true"-->
                          <div>
                            <el-form-item :label="shuju.label" v-if="shuju.value == ''"  prop="value" :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                              <el-input placeholder="请输入" v-model="shuju.value">
                                <template slot="append">
                                  <div>{{shuju.unit}}</div>
                                </template>
                              </el-input>
                              <span style="color: #00a7ff;">{{shuju.hint}}</span>
                            </el-form-item>
                            <el-form-item :label="shuju.label" v-else>
                              <el-input placeholder="请输入" v-model="shuju.value">
                                <template slot="append">
                                  <div>{{shuju.unit}}</div>
                                </template>
                              </el-input>
                              <span style="color: #00a7ff;">{{shuju.hint}}</span>
                            </el-form-item>
                          </div>
                          <!--<el-form-item :label="shuju.label" v-else>-->
                          <!--  <el-input placeholder="请输入" v-model="shuju.value">-->
                          <!--    <template slot="append">-->
                          <!--      <div>{{shuju.unit}}</div>-->
                          <!--    </template>-->
                          <!--  </el-input>-->
                          <!--  <span style="color: #00a7ff;">{{shuju.hint}}</span>-->
                          <!--</el-form-item>-->
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                  <div v-if="item.billing_model == 'fixed_price'">
                    <div>
                      <el-row style="margin-top: 20px;">
                        <el-col :span="12" v-for="(it,idx) in item.data.fixed_price" :key="idx">
                          <el-form-item :label="it.label" v-if="it.value == ''"  prop="value" :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                            <el-input placeholder="请输入" v-model="it.value">
                              <template slot="append">
                                <div>{{it.unit}}</div>
                              </template>
                            </el-input>
                            <span style="color: #00a7ff;">{{it.hint}}</span>
                          </el-form-item>
                          <el-form-item :label="it.label" v-else>
                            <el-input placeholder="请输入" v-model="it.value">
                              <template slot="append">
                                <div>{{it.unit}}</div>
                              </template>
                            </el-input>
                            <span style="color: #00a7ff;">{{it.hint}}</span>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else>
                <div v-if="item.switch == true">
                  <el-row style="margin-top: 20px;" v-if="item.billing_model == 'mileage'">
                    <el-col :span="12" v-for="(shuju1,xiabiao1) in item.data.mileage" :key="xiabiao1">
                      <el-form-item :label="shuju1.label" v-if="shuju1.value == ''"  prop="value" :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                        <el-input placeholder="请输入" v-model="shuju1.value">
                          <template slot="append">
                            <div>{{shuju1.unit}}</div>
                          </template>
                        </el-input>
                        <span style="color: #00a7ff;">{{shuju1.hint}}</span>
                      </el-form-item>
                      <el-form-item :label="shuju1.label" v-else>
                        <el-input placeholder="请输入" v-model="shuju1.value">
                          <template slot="append">
                            <div>{{shuju1.unit}}</div>
                          </template>
                        </el-input>
                        <span style="color: #00a7ff;">{{shuju1.hint}}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row style="margin-top: 20px;" v-if="item.billing_model == 'fixed_price'">
                    <el-col :span="12" v-for="(shuju1,xiabiao1) in item.data.fixed_price" :key="xiabiao1">
                      <el-form-item :label="shuju1.label" v-if="shuju1.value == ''"  prop="value" :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                        <el-input placeholder="请输入" v-model="shuju1.value">
                          <template slot="append">
                            <div>{{shuju1.unit}}</div>
                          </template>
                        </el-input>
                        <span style="color: #00a7ff;">{{shuju1.hint}}</span>
                      </el-form-item>
                      <el-form-item :label="shuju1.label" v-else>
                        <el-input placeholder="请输入" v-model="shuju1.value">
                          <template slot="append">
                            <div>{{shuju1.unit}}</div>
                          </template>
                        </el-input>
                        <span style="color: #00a7ff;">{{shuju1.hint}}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>

            </div>
          </div>
        </el-col>

        <!--右-->
        <el-col :span="6">
          <div class="provinceAndCity">
            <div class="provinceAndCity_t">
              <span style="vertical-align: middle">区域：</span>
              <el-checkbox v-model="selectAll" @change="handleCheckAllChange">全选</el-checkbox>
            </div>
            <!--省市区-->
            <div class="provinceAndCity_b">
              <!--@check="treeCheck"  实现单选-->
              <el-tree
                  :data="city"
                  ref="tree"
                  show-checkbox
                  node-key="value"
                  :default-checked-keys="[keys]"
                  @check-change="handleCheckChange"
                  :props="defaultProps">
              </el-tree>
            </div>

          </div>
        </el-col>

      </el-row>

    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">提交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
//引入的接口
import {
  taximeterget_city
} from '@/api/yunli'
import {
  Addtaximeters,
  Edittaximeters,
  Detailstaximeters,
  taximeterget_config
} from "@/api/custom";

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict:Object,
    //父级id
    id:Number
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status:0}, this.data),
      //验证规则
      rules: {
        name: [
          { required: true, message: '请输入公式名称', trigger: 'blur' },
        ],
        priority: [
          { required: true, message: '请选择优先级', trigger: 'change' },
        ],
        taximeter_type: [
          { required: true, message: '请选择计价类型', trigger: 'change' },
        ],
        unit:[
          { required: true, message: '请输入', trigger: 'change' },
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 区域 全选按钮
      selectAll:false,

      // 省市区
      //城市列表
      city:[],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      //默认选中的节点
      keys:[],

      // 配置数据
      dataList:[],

      // 选中服务区域的数据
      selectList:[],

      //是否验证
      yanzheng:false,

      radio:'0'

    };
  },
  mounted() {
    if (this.data) {
      console.log('编辑')
      console.log(this.data);
      // this.form = Object.assign({}, this.data);
      this.isUpdate = true;
      // 获取详情
      Detailstaximeters(this.id,this.data.id).then(res => {
        console.log(res);
        this.form.name = res.data.name;
        this.form.priority = res.data.priority;
        this.form.taximeter_type = res.data.taximeter_type;
        this.form.status = res.data.status;
        this.dataList = res.data.config;
        this.selectList = res.data.coverage;
        // 绑定区域
        this.keys = [];
        res.data.coverage.forEach(item => {
          // item.value = parseInt(item.value);
          this.keys.push(item.value)
        });

        // tree默认绑定的数据
        this.$refs.tree.setCheckedKeys(this.keys)
        // 是否全选
        // if(res.data.coverage.length >= 399){
        //   this.selectAll = true;
        // }else {
        //   this.selectAll = false;
        // }
      })
    } else {
      this.form = {
        status:0
      };
      this.isUpdate = false;
      //调用获取配置
      this.getConfig();
    }
    //调用获取城市列表
    this.getCity();
  },
  methods: {
    //获取配置
    getConfig(){
      taximeterget_config(this.id).then(res => {
        console.log(res)
        this.dataList = res.data;
      })
    },

    // 获取城市列表
    getCity(){
      taximeterget_city().then(res => {
        console.log(res);
        this.city = res.data;
      })
    },

    // 关闭状态
    changeStatus(){
      this.form.status = 1;
    },
    // 开启状态
    offState(){
      this.form.status = 0;
    },

    //城市单选
    // treeCheck(data, list) {
    //   //data 该节点所对应的对象、list 树目前的选中状态对象
    //   //选中事件在选中后执行，当lis中有两个选中时，使用setCheckedKeys方法，选中一个节点
    //   //单选实现
    //   if (list.checkedKeys.length > 0) {
    //     //单选实现
    //     this.$refs.tree.setCheckedKeys([data.value])
    //   } else {
    //     //取消当前选中节点
    //     this.$refs.tree.setCheckedKeys([])
    //   }
    // },

    //点击拖车下的复选框
    dianji(value){
      // console.log(value);
      this.yanzheng = value;
    },

    //城市全选
    handleCheckAllChange(val) {
      if (this.selectAll) {
        this.$refs.tree.setCheckedNodes(this.city);
      } else {
        this.$refs.tree.setCheckedKeys([]);
      }
    },
    //获取选择的区域
    handleCheckChange(){
      //console.log(this.$refs.tree.getCheckedNodes());  // 这是选中节点数组
      this.selectList = this.$refs.tree.getCheckedNodes();
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(this.selectList.length == 0){
            this.$message.error('请选择服务区域');
          }else {
            let data = {
              name: this.form.name,
              priority: this.form.priority,
              taximeter_type: this.form.taximeter_type,
              status: this.form.status,
              config: this.dataList,
              coverage: this.selectList,
              insurance_id: this.id
            }
            // 判断是创建还是编辑
            if(this.isUpdate === false){
              Addtaximeters(this.id,data).then(res => {
                this.loading = false;
                if (res.code === 200) {
                  this.$message.success(res.msg);
                  if (!this.isUpdate) {
                    this.form = {};
                  }
                  this.updateVisible(false);
                  this.$emit('done');
                } else {
                  this.$message.error(res.msg);
                }
              })
            }else {
              Edittaximeters(this.id,this.data.id,data).then(res => {
                this.loading = false;
                if (res.code === 200) {
                  this.$message.success(res.msg);
                  if (!this.isUpdate) {
                    this.form = {};
                  }
                  this.updateVisible(false);
                  this.$emit('done');
                } else {
                  this.$message.error(res.msg);
                }
              })
            }
          }

        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
  }
}
</script>

<style scoped lang="scss">
.pricing{
  margin: 15px;
}
.pricingRules{
  background: #ffffff;
  border: 1px solid #e3e4e9;
  padding: 10px;
}
.provinceAndCity{
  background: #ffffff;
  border: 1px solid #e3e4e9;
  padding: 10px;
}


.pricingRules_t_bg{
  padding: 10px 15px 10px 15px;
  background: #f0f1f3;
  border: 1px solid #dfe2e7;
  font-size: 16px;
  font-weight: 500;
  color: #242832;
}

.trip{
  padding: 15px;
}

.provinceAndCity_b{
  margin-top: 10px;
}

</style>
